<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <div class="content">
            <ul class="sel"><li :class="[activeIndex == index ? 'active' : '']" v-for="(item, index) in selList" :key="index" @click="setData(index)">{{item.name}}</li></ul>
            <div class="wrap">
                <div class="item" v-for="(item, index) in newList" :key="index" @click="toPage(item)">
                    <img :src="item.img" alt="" srcset="">
                    <p>{{item.title}}</p>
                    <div class="bot">
                        <i>{{setType(item.type)}}</i>
                        <span>{{item.date}}</span>
                    </div>
                </div>
                <div class="none" v-if="newList.length == 0">
                - 暂无数据 -
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            selList:[{
                name: '全部',
                id: 0,
                type: ''
            },{
                name: '产业元宇宙',
                id: 1,
                type: 'cy'
            },{
                name: '虚拟人',
                id: 2,
                type: 'xn'
            },{
                name: 'NFT',
                id: 3,
                type: 'nft'
            },{
                name: '川链',
                id: 4,
                type: 'cl'
            },{
                name: '平川云平台',
                id: 5,
                type: 'pcy'
            },],
            list: [{
                title: '中食安泓瘦吧减脂元宇宙空间站',
                type: 'cy',
                date: '2022-4-3',
                img: require('../../assets/images/case/case1.png'),
                href: 'https://external.333f.com/zhongshi-client/index',
            },{
                title: '广交会中国建设银行线上营业厅',
                type: 'cy',
                date: '2022-4-3',
                img: require('../../assets/images/case/case2.png'),
                href: 'https://external.333f.com/online-CCB'
            },{
            //     title: 'NFT',
            //     type: 'nft',
            //     date: '2022-4-3',
            //     img: require('../../assets/images/case/case3.png')
            // },{
            //     title: '白云机场建党100周年',
            //     type: 'cy',
            //     date: '2022-4-3',
            //     img: require('../../assets/images/case/case4.png')
            // },{
            //     title: '广汽本田线上馆',
            //     type: 'cy',
            //     date: '2022-4-3',
            //     img: require('../../assets/images/case/case5.png')
            // },{
            //     title: '广东联通线上馆',
            //     type: 'cy',
            //     date: '2022-4-3',
            //     img: require('../../assets/images/case/case6.png')
            // },{
                title: '广州汽车产业万亿战略发布会',
                type: 'cy',
                date: '2022-4-3',
                img: require('../../assets/images/case/case7.png'),
                href: 'https://external.333f.com/autoshow_m/index'
            },{
                title: '致同党建线上馆',
                type: 'cy',
                date: '2022-4-3',
                img: require('../../assets/images/case/case8.png'),
                href: 'https://external.333f.com/zhitong-exhibition-new/#/'
            },],
            newList:[],
            activeIndex: 0,
        }
    },
    created(){
        this.newList = this.list;
    },
    methods: {
        toPage(item){
            if(!!item.href){
                window.open(item.href)
            }
        },
        setData(index){
            this.activeIndex = index
            let t = this.selList[index].type
            this.newList = []
            for(let i = 0; i < this.list.length; i++){
                if(t == this.list[i].type) this.newList.push(this.list[i])
            }
            if(t == '')
                this.newList = this.list;
        },
        setType(t){
            console.log(t)
            if(t == 'cy') return "产业元宇宙"
            if(t == 'xn') return "虚拟人"
            if(t == 'nft') return "NFT"
            if(t == 'cl') return "川链"
            if(t == 'pcy') return "平川云平台"
        }
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    // height: 100%;
    background: #fdfdfd;
    display: flex;
    justify-content: center;
    .content{
        // height: 1292px;
        background: #fdfdfd;
        display: inline-block;
        width: 1645px;
        padding-bottom: 92px;
        .sel{
            display: flex;
            justify-content: center;
            align-items: center;
            color: #F76000;
            font-size: 16px;
            margin-top: 64px;
            margin-bottom: 64px;
            li{
                padding: 13px 46px;
                border-radius: 100px;
                border: 1px #F76000 solid;
                display: flex;
                margin: 0 16px;
                // transform: scale(.8);
                // transform-origin: center;
            }
            }
            .active{
                background: #F76000;
                color: #fff;
            }
            .wrap{
                display: flex;
                flex-wrap: wrap;
                // justify-content: flex-start;
                padding: 0 13px;
                // height: 1023.24px;
                // width: 1645px;
                // overflow: hidden;
                // overflow-y: scroll;
                justify-content: space-between;
                .item{
                    width: 386px;
                    height: 491px;
                    background: #FFFFFF;
                    box-shadow: 0px 3px 7px 1px rgb(0 0 0 / 8%);
                    border-radius: 16px;
                    text-align: center;
                    margin-bottom: 9px;
                    // margin-right: 45px;
                    img{
                        width: 338px;
                        height: 358px;
                        margin: 24px;
                        border-radius: 4px;
                    }
                    p{
                        color: #333;
                        font-size: 18px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                        padding: 0 24px 12px;
                        text-align: left;
                    }
                    .bot{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;
                        i{
                            background: #FFEFE5;
                            border-radius: 60px;
                            font-style: normal;
                            color: #FF6E12;
                            padding: 4px 8px;
                            margin-left: 24px;
                        }
                        span{
                            color: #ADB5BD;
                            margin-right: 24px;
                        }
                    }
                }
                /* 如果最后一行是3个元素 */
                .item:last-child:nth-child(4n - 1) {
                    margin-right: calc(24% + 4% / 3);
                }
                /* 如果最后一行是2个元素 */
                .item:last-child:nth-child(4n - 2) {
                    margin-right: calc(48% + 8% / 3);
                }
                .none{
                    width: 100%;
                    text-align: center;
                    font-size: 20px;
                    color: #959595;
                    padding-top: 50px;
                }
        }
    }
}
</style>